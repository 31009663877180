import Image from "../../components/Image/Image";
export const strategiesDescriptions = {
    a: <span>
        This strategy targets the <strong>EURUSD</strong> pair on the <strong>1HR</strong> timeframe exclusively during the <strong>New York</strong>  session. It revolves around analyzing the most recent reversal to establish the prevailing market bias. Traders leverage this bias to identify and execute trades that align with the overarching market sentiment within the confines of the New York session timeframe. By adhering to this approach, traders aim to capitalize on opportunities that are in harmony with the current directional trend, maximizing the potential for profitable outcomes while mitigating risks associated with trading against the prevailing market sentiment. Profit targets are set at the nearest Fibonacci Value Zone (FVG) or Point of Interest (POI), with an ideal risk-to-reward ratio of at least 1:1.
    </span>,
    b: <span>
        This strategy targets the <strong>EURUSD</strong> pair on the <strong>1HR</strong> timeframe. The trendline is to be created depending on multiple bumps (lower highs for buy and higher lows for sell). When a change of character and break of structure is formed outside of the asian range box, then the direction is decided.
    </span>,
    c: <span>
        This strategy targets the <strong>EURUSD</strong> pair on the <strong>30M</strong> timeframe. When there is a previous impulsive move to the upside or downside, during the London session, wait for a breakout to occur. Price will move towards a certain direction. Create 2 pending orders outside of the asian range box at the beginning of London session. Take profit a 1:2 risk to reward ratio.
    </span>,
    d: <span>
        This strategy targets the <strong>EURUSD</strong> pair on the <strong>5M</strong> timeframe. The first thing to look for is the 5th change of character formation. Identify whether it is bullish and bearish. Trade in accordance to the change of character with stop loss at the asian range box limits and take profit at 1:2 risk to reward ratio.
    </span>
}

export const strategiesNames = {
    a: "Day Ago Strategy",
    b: "Change of Character and Trendline Strategy",
    c: "30 Minute Breakout Strategy",
    d: "Multiple Change of Character Strategy"
}

export const strategiesImages = {
    a: <Image src="/assets/StrategyA.png" caption={"The image was taken on trading view using free indicators (sessions, FVG, SMC, range box)."} />,
    b: <Image src="/assets/StrategyB.png" caption={"The image was taken on trading view using free indicators (sessions, SMC, range box)."} />,
    c: <Image src="/assets/StrategyC.png" caption={"The image was taken on trading view using free indicators (sessions, SMC, range box)."} />,
    d: <Image src="/assets/StrategyD.png" caption={"The image was taken on trading view using free indicators (sessions, SMC, range box)."} />
}