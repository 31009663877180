import styled from "styled-components";

export const StyledDropdown = styled.select`
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`
export const StyledLabel = styled.div`
    font-size: 16px;
    color: gray;
    margin-bottom: 5px;
`