import React from 'react';
import { StyledInput, StyledLabel, StyledError } from './InputStyles';

const Input = ({ name, placeholder, value, onChange = () => { }, type = "text", width, label, error }) => {
    return <div>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput name={name} width={width} type={type} placeholder={placeholder} value={value} onChange={onChange} />
        <StyledError width={width} >{error}</StyledError>
    </div>
};

export default Input;
