import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ContentContainer from "../components/ContentContainer/ContentContainer";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import { Helmet } from "react-helmet";
import {
  strategiesDescriptions,
  strategiesNames,
  strategiesImages,
} from "../data/strategies/strategies";

const Strategies = () => {
  return (
    <>
      <Helmet>
        <title>Dawn's Trading Journal - Forex Strategies Page</title>
        <meta
          name="description"
          content="This page shows different types of my forex strategies."
        />
        <meta
          name="keywords"
          content="forex, strategies, dawn carrasco, dawn, this"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Dawn Carrasco" />
        <meta name="google-adsense-account" content="ca-pub-1161059717775888"></meta>
      </Helmet>
      <div>
        <Navbar />
        <PageWrapper>
          <ContentContainer>
            {Object.keys(strategiesNames)?.map((key) => (
              <>
                <h1>{strategiesNames[key]}</h1>
                {strategiesDescriptions[key]}
                <br />
                {strategiesImages[key]}
                <br />
              </>
            ))}
          </ContentContainer>
        </PageWrapper>
      </div>
    </>
  );
};

export default Strategies;
