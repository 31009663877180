import React, { useRef, useEffect, useState } from "react";
import { StyledCard } from "./CardStyles";

const Card = ({ fadeDirection = "none", children, variant, overrideStyles }) => {
    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                // eslint-disable-next-line
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <StyledCard style={overrideStyles} variant={variant} direction={fadeDirection} isVisible={isVisible} ref={cardRef}>
            {children}
        </StyledCard>
    );
};

export default Card;