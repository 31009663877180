import React from 'react';
import { StyledImageContainer, StyledImage, StyledCaption } from './ImageStyles';

const Image = ({ src, alt, size, caption }) => {
    return (
        <StyledImageContainer size={size}>
            <div><StyledImage src={src} alt={alt} /></div>
            {caption ? <StyledCaption>{caption}</StyledCaption> : null}
        </StyledImageContainer>
    );
};

export default Image;
