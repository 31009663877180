import styled, { keyframes, css } from 'styled-components';

// Keyframes for fading in from different directions
const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Determine animation based on direction prop
const determineAnimation = (direction) => {
  switch (direction) {
    case 'left':
      return fadeInLeft;
    case 'right':
      return fadeInRight;
    case 'bottom':
      return fadeInBottom;
    case 'top':
      return fadeInTop;
    default:
      return fadeInLeft;
  }
};

// Styled component for the card
export const StyledCard = styled.div`
  padding: 20px;
  width: calc(100% - 40px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;

  ${({ direction, isVisible }) =>
    isVisible && direction !== "none" &&
    css`
      animation: ${() => determineAnimation(direction)} 0.5s ease-in-out forwards;
    `}
  
  ${({ direction }) => direction === "none" &&
    `opacity: 1;
    `
  }
  
  ${({ variant }) => variant === "outline" &&
    `background-color: transparent;
      border: 2px solid #0f0f0f;
      padding: 10px;
      width: calc(100% - 20px);
    `
  }
`;