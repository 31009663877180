import styled from 'styled-components';

export const StyledImageContainer = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

// Styled component for the avatar image
export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover; /* Prevents image stretching */
`;

export const StyledCaption = styled.div`
  color: #7E7E7E;
  font-size: 13px;
  text-align: right;
`;