import Image from "../../components/Image/Image";

export const entry_20240101 = {
    date: "2024-01-01",
    name: "a",
    strategy: "a",
    currency: "EURUSD",
    entryType: "Backtest",
    result: "LOSE",
}

export const entry_20240811 = {
    date: "2024-08-11",
    name: "b",
    strategy: "b",
    currency: "EURUSD",
    entryType: "Backtest",
    result: "WIN",
    entry: {
        b: "The result of the backtest was a win due to rules being followed and set in place."
    }
}

export const entry_20240813 = {
    date: "2024-08-13",
    name: "b",
    strategy: "b",
    currency: "EURUSD",
    entryType: "Forward Test",
    result: "Unknown",
    entry: {
        a: <Image src="/assets/Entry_20240813.png" />,
        b: "The result of the backtest was a win due to rules being followed and set in place."
    }
}

export const entry_20240816 = {
    date: "2024-08-16",
    name: "b",
    strategy: "b",
    currency: "EURUSD",
    entryType: "Forward Test",
    result: "WIN",
    entry: {
        a: <Image src="/assets/Entry_20240816.png" />,
        b: "There was a huge movement to the upside previously. Then, prices created a trendline to the downside. As the strategy suggests, after an internal change of character is formed, there would be another impulse to the upside."
    }
}

export const entry_20240823 = {
    date: "2024-08-23",
    name: "c",
    strategy: "c",
    currency: "EURUSD",
    entryType: "Forward Test",
    result: "WIN",
    entry: {
        a: <Image src="/assets/Entry_20240823.png" />,
        b: "On the 30 minute time frame for EURUSD, there was a breakout to the downside. The trade was won as a close before 2% when it was bouncing off on a buy order block."
    }
}

export const entry_20240830 = {
    date: "2024-08-30",
    name: "b",
    strategy: "b",
    currency: "EURUSD",
    entryType: "Forward Test",
    result: "LOSE",
    entry: {
        a: <Image src="/assets/Entry_20240830.png" />,
        b: "On the 1 hour time frame for EURUSD, there was a change of character to the upside that was formed. It went up and formed a false break out then suddenly went down on news release. Two trades were entered and both stop losses were hit."
    }
}