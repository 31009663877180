import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ContentContainer from "../components/ContentContainer/ContentContainer";
import Card from "../components/Card/Card";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import Chip from "../components/Chip/Chip";
import { Helmet } from "react-helmet";
import {
  strategiesDescriptions,
  strategiesImages,
} from "../data/strategies/strategies";
import { journalEntries } from "../data/journal-entries-2024";

const latestEntry = journalEntries.sort(
  (a, b) => new Date(b.date) - new Date(a.date),
)[0];

const JournalEntry = () => {
  return (
    <>
      <Helmet>
        <title>Dawn's Trading Journal - Journal Entries</title>
        <meta
          name="description"
          content="This page exhibits my latest journal entry"
        />
        <meta
          name="keywords"
          content="journal entry, forex, forex trading, forex journal, entries, this"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Dawn Carrasco" />
        <meta name="google-adsense-account" content="ca-pub-1161059717775888"></meta>
      </Helmet>
      <div>
        <Navbar />
        <PageWrapper>
          <ContentContainer>
            <h1>
              {new Date(latestEntry.date).toLocaleDateString("en-US", {
                dateStyle: "long",
              })}
            </h1>
            <div style={{ display: "flex", gap: "5px" }}>
              <Chip color="blue">{latestEntry.currency.toUpperCase()}</Chip>
              <Chip color="black">{latestEntry.entryType.toUpperCase()}</Chip>
              <Chip
                color={
                  latestEntry.result.toLowerCase() === "win" ? "green" : "red"
                }
              >
                {latestEntry.result.toUpperCase()}
              </Chip>
            </div>
            <br />
            <Card overrideStyles={{ border: "2px solid #2b60c9" }}>
              <h1>The Strategy</h1>
              {strategiesDescriptions[latestEntry.strategy]}
              <br />
              <br />
              {strategiesImages[latestEntry.strategy]}
            </Card>
            <br />
            {latestEntry?.entry?.a && (
              <div>
                <Card>{latestEntry?.entry?.a}</Card>
                <br />
              </div>
            )}
            <div>{latestEntry?.entry?.b}</div>
          </ContentContainer>
        </PageWrapper>
      </div>
    </>
  );
};

export default JournalEntry;
