import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ContentContainer from "../components/ContentContainer/ContentContainer";
import Card from "../components/Card/Card";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import Image from "../components/Image/Image";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Dawn's Trading Journal - Journey Into Trading</title>
        <meta
          name="description"
          content="Trading Journal - Dawn's Journey Into Trading is a comprehensive account of Dawn's experience and insights into the world of trading. It offers a detailed narrative of her journey, including successes, failures, strategies, and lessons learned along the way. From novice to seasoned trader, readers will find valuable information and inspiration to navigate their own trading paths."
        />
        <meta
          name="keywords"
          content="Trading journal, trading experience, trading insights, trading strategies, trading journey, trading successes, trading failures, trading lessons, novice trader, seasoned trader, trading inspiration."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Dawn Carrasco" />
        <meta name="google-adsense-account" content="ca-pub-1161059717775888"></meta>
      </Helmet>
      <div>
        <Navbar />
        <PageWrapper>
          <ContentContainer>
            <div style={{ fontSize: "50px", color: "#303030" }}>
              Trade With Me:
            </div>
            <Card
              overrideStyles={{ backgroundColor: "#1d3159", color: "white" }}
            >
              <span style={{ float: "left", marginRight: "15px" }}>
                <Image size="150" src="/assets/Dawn_Front.jpg" />
              </span>
              Hey there, fellow traders! Let me introduce myself – I'm Dawn, a
              software developer by day and a passionate trader by night! 🚀 My
              journey into the thrilling world of trading kicked off in 2012,
              and let me tell you, it's been one wild ride! I've dabbled in all
              sorts of strategies, from the traditional to the avant-garde, and
              boy, have I seen it all – wins, losses, and everything in between!
              But you know what? Despite the ups and downs, I'm still here,
              eager as ever to crack the code and find MY trading style. From
              following signals to devouring trading courses, and even tinkering
              with trading robots – I've done it all! And let's not forget about
              copy trading and attempting to get my account managed... Phew,
              talk about a rollercoaster of experiences! 🤯 As a software
              developer, I bring a unique perspective to the trading table –
              always analyzing, iterating, and fine-tuning my approach to match
              the ever-changing market landscape. 💻 And hey, while the journey
              may have its twists and turns, I'm not giving up anytime soon! So,
              buckle up, folks! Join me on this exhilarating adventure as I
              share my wins, losses, and everything in between. Together, let's
              unlock the secrets of successful trading and journey towards
              financial freedom! 🌟
            </Card>
            <div style={{ marginBottom: "90px" }}></div>
          </ContentContainer>
        </PageWrapper>
      </div>
    </>
  );
};

export default Home;
