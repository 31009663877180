import React from 'react';
import Card from '../Card/Card';
import { StyledPageWrapper } from './PageWrapperStyles';

const PageWrapper = ({ children }) => {
    return (
        <StyledPageWrapper>
            <Card overrideStyles={{ backgroundColor: "#ffebd9", paddingTop: "30px", marginTop: "-10px" }}>
                <b>DISCLAIMER:</b> I am not a licensed financial advisor, and the information shared on this blog should not be construed as financial advice. All content provided on this platform is for informational purposes only.
                Trading and investing in financial markets carry inherent risks, and past performance is not indicative of future results. Any actions taken based on the content of this blog are at your own risk. I cannot guarantee the accuracy or completeness of the information presented, and I do not assume any responsibility for any financial losses or damages incurred as a result of your reliance on the content provided here.
                The views and opinions expressed in this blog are solely mine and do not necessarily reflect the views or opinions of any organization I am affiliated with.
                Remember, trading involves risk, and there are no guaranteed wins in the financial markets. Always conduct your own research and consult with a qualified financial advisor before making any investment decisions.
            </Card>
            {children}
        </StyledPageWrapper>
    );
};

export default PageWrapper;
