import React from 'react';
import { LogoTitle, LogoContainer } from './LogoStyles';

const Logo = () => {
    return (
        <LogoContainer>
            <LogoTitle>Dawn's Trading Journal</LogoTitle>
        </LogoContainer>
    );
};

export default Logo;
