import React from "react";
import { StyledDropdown, StyledLabel } from "./DropdownStyled";

const Dropdown = ({ options, label, onChange = () => { }, value, overrideStyles }) => {
    return <div>
        <StyledLabel>{label}</StyledLabel>
        <StyledDropdown onChange={onChange} value={value} style={overrideStyles}>
            {
                Object.keys(options)?.map((key) =>
                    <option value={key}>{options[key]}</option>
                )
            }
        </StyledDropdown>
    </div>
}

export default Dropdown;