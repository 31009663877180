import React, { useState, useEffect, useRef } from 'react';
import { NavContainer, NavItemsContainer, MenuList, MenuItem, DropdownButton, DropdownMenu, DropdownMenuItem } from './NavbarStyles';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

const Navbar = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const menuButtonRef = useRef(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickAway = (e) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            menuButtonRef.current &&
            !menuButtonRef.current.contains(e.target)
        ) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickAway);

        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, []);

    const navItems = [
        { link: "/", displayText: "About Me" },
        { link: "/search", displayText: "Search Entries" },
        { link: "/strategies", displayText: "Strategies" },
        { link: "/trading-psychology", displayText: "Trading Psychology" },
        { link: "/journal-entry", displayText: "Latest Journal Entry" },
    ]

    return (
        <NavContainer>
            <Link to="/" style={{ textDecoration: "none" }}><Logo></Logo></Link>
            <NavItemsContainer>
                <DropdownButton onClick={toggleDropdown} ref={menuButtonRef}>Navigate The Site</DropdownButton>
                <MenuList>
                    {
                        navItems?.map(item => <MenuItem>
                            <Link to={item.link} style={{ textDecoration: "none" }}>{item.displayText}</Link>
                        </MenuItem>)
                    }
                </MenuList>
                <DropdownMenu ref={dropdownRef} showDropdown={showDropdown}>
                    {/* Render dropdown items here */}
                    {
                        navItems?.map(item => <DropdownMenuItem>
                            <Link to={item.link} style={{ textDecoration: "none" }}>{item.displayText}</Link>
                        </DropdownMenuItem>)
                    }
                </DropdownMenu>
            </NavItemsContainer>
        </NavContainer>
    );
};

export default Navbar;
