import styled from 'styled-components';

export const StyledInput = styled.input`
padding: 7px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;

    ${({ width }) => width &&
        `width: ${width}`
    }
`;

export const StyledLabel = styled.div`
    font-size: 16px;
    color: gray;
    margin-bottom: 5px;
`

export const StyledError = styled.div`
    color: red;
    font-size: 12px;
    white-space: wrap;
    text-wrap: wrap;

    ${({ width }) => width &&
        `width: ${width}`
    }
`