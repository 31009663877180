import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  margin: 0px auto;
  max-width: 1000px;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({ gap }) => gap};

  @media (max-width: 768px) {
    padding: 20px;
  }
`;