import styled from 'styled-components';

export const LogoContainer = styled.div`
    display: block;
    height: auto;
    z-index: 999;
    position: relative;
    margin-left: 0px;
    margin-top: 10px;
`;

export const LogoTitle = styled.div`
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
    color: #f18c28;
    text-align: center;
`;