import React from 'react';
import { StyledChip } from './ChipStyles';

const Chip = ({ children, color = "blue" }) => {
    return (
        <StyledChip color={color} >{children}</StyledChip>
    );
};

export default Chip;
