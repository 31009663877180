import {
  entry_20240101,
  entry_20240811,
  entry_20240813,
  entry_20240816,
  entry_20240823,
  entry_20240830
} from "./strategies/journal-entry-20240101";

export const journalEntries = [
  { ...entry_20240101 },
  { ...entry_20240811 },
  { ...entry_20240813 },
  { ...entry_20240816 },
  { ...entry_20240823 },
  { ...entry_20240830 },
];
