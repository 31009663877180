import React from 'react';
import { StyledContainer } from './ContentContainerStyles';

const ContentContainer = ({ children, flexDirection = "column", gap = "0px", style }) => {
    return (
        <StyledContainer flexDirection={flexDirection} gap={gap} style={style}>
            {children}
        </StyledContainer>
    );
};

export default ContentContainer;
