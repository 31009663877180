import styled from 'styled-components';

export const NavContainer = styled.div`
  background-color: #2b60c9;
  padding: 20px 0px;
  width: 100%;
  height: auto;
  z-index: 9;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Drop shadow */
`;

export const NavItemsContainer = styled.nav`
  padding: 10px 0px;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0px;
  z-index: 999;

  @media (max-width: 768px) {
    padding: 0px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > * {
      margin-bottom: 10px;
    }
  }
`;

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
  z-index: 1;
  
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    display: none;
  }
`;

export const MenuItem = styled.li`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: #ffcc00;
    }
  }
`;

export const DropdownButton = styled.button`
  display: none;
  background-color: #2fa1b4;
  border: none;
  color: white;
  padding: 10px;
  width: 200px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;

  @media (max-width: 768px) {
    display: block;
    /* Your dropdown button styles */
  }
`;

export const DropdownMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${props => (props.showDropdown ? 'block' : 'none')};
    top: 100px;
    width: 300px;
    z-index: 999;
    border-radius: 10px;
    position: absolute;
    background: white;
    padding: 5px 15px;
    border: 3px solid #2b60c9;
  }
`;

export const DropdownMenuItem = styled.div`
  display: block;
  text-decoration: none;

  > a {
    text-decoration: none;
    color: #0f0f0f;

    &:hover {
      color: #ffcc00;
    }
  }
`;