import styled from 'styled-components';

export const StyledChip = styled.div`
  padding: 5px 10px;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ color }) => color === "orange" &&
    `background-color: #f99c37;
      color: #5b3109;
    `
  }

  ${({ color }) => color === "red" &&
    `background: #be3333;
    `
  }

  ${({ color }) => color === "green" &&
    `background-color: #18bb1f;
    `
  }

  ${({ color }) => color === "blue" &&
    `background-color: #2c60c9;
    `
  }

  ${({ color }) => color === "black" &&
    `background-color: #303030;
    `
  }
`;