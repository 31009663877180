import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Dropdown from "../components/Dropdown/Dropdown";
import Input from "../components/Input/Input";
import Chip from "../components/Chip/Chip";
import Card from "../components/Card/Card";
import ContentContainer from "../components/ContentContainer/ContentContainer";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import { Helmet } from "react-helmet";
import {
    strategiesNames,
    strategiesDescriptions,
    strategiesImages,
} from "../data/strategies/strategies";
import { journalEntries } from "../data/journal-entries-2024";

const Search = () => {
    const [dropdownValue, setDropdownValue] = useState("all");
    const [fromDateValue, setFromDateValue] = useState(null);
    const [toDateValue, setToDateValue] = useState(null);
    const [displayedJournalEntries, setDisplayedJournalEntries] =
        useState(journalEntries);

    const onDropdownChange = (e) => {
        const { value } = e.target;

        setDropdownValue(value);
        const displayedEntries =
            value === "all"
                ? [...journalEntries]
                : journalEntries?.filter((entry) => value === entry.name);
        setDisplayedJournalEntries(displayedEntries);
    };

    const handleDateChange = (fromDate, toDate) => {
        if (fromDate && toDate) {
            if (fromDate <= toDate) {
                const displayedEntries = journalEntries?.filter(
                    (entry) => new Date(entry.date) >= new Date(fromDate) && new Date(entry.date) <= new Date(toDate)
                );
                setDisplayedJournalEntries(displayedEntries);
            }
        } else if (fromDate) {
            const displayedEntries = journalEntries?.filter(
                (entry) => new Date(entry.date) >= new Date(fromDate)
            );
            setDisplayedJournalEntries(displayedEntries);

        } else if (toDate) {
            const displayedEntries = journalEntries?.filter(
                (entry) => new Date(entry.date) <= new Date(toDate)
            );
            setDisplayedJournalEntries(displayedEntries);
        }
    }

    const onDateFromChange = (e) => {
        const { value } = e.target;

        setFromDateValue(value);
        handleDateChange(value, toDateValue);
    };

    const onDateToChange = (e) => {
        const { value } = e.target;

        setToDateValue(value);
        handleDateChange(fromDateValue, value);
    };

    return (
        <>
            <Helmet>
                <title>Dawn's Trading Journal - Forex Strategies Page</title>
                <meta
                    name="description"
                    content="This page shows different types of my forex strategies."
                />
                <meta
                    name="keywords"
                    content="forex, strategies, dawn carrasco, dawn, this"
                />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Dawn Carrasco" />
                <meta name="google-adsense-account" content="ca-pub-1161059717775888"></meta>
            </Helmet>
            <div>
                <Navbar />
                <PageWrapper>
                    <ContentContainer flexDirection="row" gap="5px" style={{ display: "flex", width: "100%" }}>
                        <Dropdown
                            style={{ display: "flex", justifyContent: "flex-start", flexGrow: 1 }}
                            value={dropdownValue}
                            options={{ all: "ALL", ...strategiesNames }}
                            label="Strategy Name"
                            onChange={onDropdownChange}
                        />
                        <Input
                            value={fromDateValue}
                            type="date"
                            label="Date From"
                            width="200px"
                            onChange={onDateFromChange}
                            error={
                                fromDateValue && toDateValue && new Date(toDateValue) < new Date(fromDateValue)
                                    ? "To date should not be greater than from date"
                                    : ""
                            }
                        />
                        <Input
                            value={toDateValue}
                            type="date"
                            label="Date To"
                            width="200px"
                            onChange={onDateToChange}
                            error={
                                fromDateValue && toDateValue && new Date(fromDateValue) > new Date(toDateValue)
                                    ? "From date value should not be greater than to date value."
                                    : ""
                            }
                        />
                    </ContentContainer>
                    <ContentContainer style={{ marginTop: "-50px" }} gap="30px">
                        {displayedJournalEntries?.length ? displayedJournalEntries?.map((journalEntry) => (
                            <div>
                                <h1>
                                    {new Date(journalEntry.date).toLocaleDateString("en-US", {
                                        dateStyle: "long",
                                    })}
                                </h1>
                                <div style={{ display: "flex", gap: "5px" }}>
                                    <Chip color="blue">
                                        {journalEntry.currency.toUpperCase()}
                                    </Chip>
                                    <Chip color="black">
                                        {journalEntry.entryType.toUpperCase()}
                                    </Chip>
                                    <Chip
                                        color={
                                            journalEntry.result.toLowerCase() === "win"
                                                ? "green"
                                                : "red"
                                        }
                                    >
                                        {journalEntry.result.toUpperCase()}
                                    </Chip>
                                </div>
                                <br />
                                <Card overrideStyles={{ border: "2px solid #2b60c9" }}>
                                    <h1>The Strategy</h1>
                                    {strategiesDescriptions[journalEntry.strategy]}
                                    <br />
                                    <br />
                                    {strategiesImages[journalEntry.strategy]}
                                </Card>
                                <br />
                                {journalEntry?.entry?.a && (
                                    <div>
                                        <Card>{journalEntry?.entry?.a}</Card>
                                        <br />
                                    </div>
                                )}
                                <div>{journalEntry?.entry?.b}</div>
                            </div>
                        )) : <Card>No Results Found</Card>}
                    </ContentContainer>
                </PageWrapper>
            </div>
        </>
    );
};

export default Search;
